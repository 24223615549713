import * as Sentry from "@sentry/astro";
import { APP_VERSION } from "astro:env/client";

Sentry.init({
  autoSessionTracking: true,
  enableTracing: true,
  release: `ocw@${APP_VERSION}`,
  debug: import.meta.env.DEV,
  environment: import.meta.env.DEV
    ? "local"
    : import.meta.env.PUBLIC_ENVIRONMENT_MODE,
  tracesSampleRate: import.meta.env.PUBLIC_SENTRY_SAMPLE_RATE,
  replaysSessionSampleRate: import.meta.env
    .PUBLIC_SENTRY_SESSION_REPLAY_SAMPLE_RATE,
  replaysOnErrorSampleRate: import.meta.env
    .PUBLIC_SENTRY_SESSION_ERROR_REPLAY_SAMPLE_RATE,
  dsn: import.meta.env.PUBLIC_SENTRY_DSN,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.browserTracingIntegration({
      tracePropagationTargets: [/\//],
    }),
  ],
});
